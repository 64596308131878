import { Component, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ApplicationStateService } from "../services/application-state.service";
import { tap } from "rxjs";

@Component({
  selector: 'portal-contact-support',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './contact-support.component.html',
  styleUrl: './contact-support.component.scss'
})
export class ContactSupportComponent implements OnInit {

  organization: string | undefined;

  constructor(
    protected appState: ApplicationStateService
  ) { }

  ngOnInit(): void {
    this.appState.subscribe(state => state.organization)
      .pipe(
        tap(organization => this.organization = organization)
      )
      .subscribe();
  }

}
